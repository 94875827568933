import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
//import createPersistedState from "vuex-persist-indexeddb";
import { company } from './mudules/company'
import { authen } from './mudules/authen'
import { utils } from './mudules/utils'
import { department } from './mudules/department'
import { division } from './mudules/division'
import { project } from './mudules/project'
import { shift } from './mudules/shift'
import { prefix } from './mudules/prefix';
import { executor } from './mudules/executor';
import { brand } from './mudules/brand';
import { machineType } from './mudules/machineType';
import { process } from './mudules/process';
import { meter } from './mudules/meter';
import { energy } from './mudules/energy';
import { machine } from './mudules/machine';
import { dumpSpot } from './mudules/dumpSpot';
import { member } from './mudules/member';
import { projectWork } from './mudules/projectWork';
import { projectRoles } from './mudules/projectRoles';
import { logDelete } from './mudules/logDelete';
import { workSheet } from './mudules/workSheet';
import { truckScale } from './mudules/truckScale';
import { hauling } from './mudules/hauling';
import { haulingMajor } from './mudules/haulingMajor';
import { workItem } from './mudules/workItem';
import { loadingSpot } from './mudules/loadingSpot';
import { loadingMajor } from './mudules/loadingMajor';
import { loading } from './mudules/loading';
import { machineDaily } from './mudules/machineDaily';
import { energyUsage } from './mudules/energyUsage';
import { supportJobType } from './mudules/supportJobType';
import { supportMachineMajor } from './mudules/supportMachineMajor';
import { supportMachine } from './mudules/supportMachine';
import { supportMachineMajorReport } from './mudules/supportMachineMajorReport';
import { machineDailyReport } from './mudules/machineDailyReport';
import { dashboard } from './mudules/dashboard';
import { bookmark } from './mudules/bookmark';
import { energySupply } from './mudules/energySupply';
import { report } from './mudules/report';
import { excavatorPerformanceDash } from './mudules/dashboard/excavatorPerformance';
import { excavatorPeriodPerformanceDash } from './mudules/dashboard/excavatorPeriodPerformance';
import { truckRFID } from './mudules/truckRFID';
import { workGroup } from './mudules/workGroup';
import { machineWorkGroup } from './mudules/machineWorkGroup';
import { energyUsageFile } from './mudules/energyUsageFile';
import { permit } from './mudules/permit';
import { energyApproval } from './mudules/energyApproval';
import { workItemType } from './mudules/workItemType';
import { energyDashboard } from './mudules/dashboard/energyDashboard';
import { processCustom } from './mudules/processCustom';
import { haulingProductionDash } from './mudules/dashboard/haulingProductionDash';
import { loadingSpotDash } from './mudules/dashboard/loadingSpotDash';
import { energyReceive } from './mudules/energyReceive';
import { fileServer } from './mudules/api/fileServer';
import { documentType } from './mudules/documentType';
import { machineDocument } from './mudules/machineDocument';
import { warehouse } from './mudules/warehouse';
import { projectTrcloud } from './mudules/projectTrcloud';

import { pmCategory } from './mudules/pm/pmCategory';
import { pmUnit } from './mudules/pm/pmUnit';
import { pmPart } from './mudules/pm/pmPart';
import { trcloud } from './mudules/api/trcloud';
import { pmTable } from './mudules/pm/pmTable';
import { pmTableItem } from './mudules/pm/pmTableItem';
import { pmActivity } from './mudules/pm/pmActivity';
import { pmRound } from './mudules/pm/pmRound';
import { pmTableMachine } from './mudules/pm/pmTableMachine';
import { pmActualRound } from './mudules/pm/pmActualRound';
import { pmFramework } from './mudules/pm/pmFramework';
import { pmAppointment } from './mudules/pm/pmAppointment';
import { pmAppointmentAction } from './mudules/pm/pmAppointmentAction';
import { pmMrApproval } from './mudules/pm/pmMrApproval';
import { pmMr } from './mudules/pm/pmMr';
import { machineBlockMr } from './mudules/machineBlockMr';
import { pmDocFile } from './mudules/pm/pmDocFile';
import { pmPartFile } from './mudules/pm/pmPartFile';
import { pmPrint } from './mudules/pmPrint';
import {pmDash} from './mudules/pm/dashboard/pmActivityDach'
import {vender} from './mudules/vender'
import {machineGroup} from './mudules/machineGroup'
import {machineGroupItem} from './mudules/machineGroupItem'
import { distanceMatrix } from './mudules/distanceMatrix';
import { reportITD } from './mudules/printITD';
import {energyConsumptionGroup} from './mudules/energyConsumptionGroup'
import { crawlerDrillDash } from './mudules/dashboard/crawlerDrillDash';
import { logLogin } from './mudules/logLogin'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  //plugins: [createPersistedState()],
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    company,
    authen,
    utils,
    department,
    division,
    project,
    shift,
    prefix,
    executor,
    brand,
    machineType,
    process,
    meter,
    energy,
    machine,
    dumpSpot,
    member,
    projectWork,
    logDelete,
    workSheet,
    projectRoles,
    truckScale,
    hauling,
    haulingMajor,
    workItem,
    loadingSpot,
    loadingMajor,
    loading,
    machineDaily,
    energyUsage,
    supportJobType,
    supportMachineMajor,
    supportMachine,    
    supportMachineMajorReport,
    machineDailyReport,
    dashboard,
    bookmark,  
    energySupply,  
    report,
    excavatorPerformanceDash,
    excavatorPeriodPerformanceDash,
    truckRFID,
    workGroup,
    machineWorkGroup,
    energyUsageFile,
    permit,
    energyApproval,
    workItemType,
    energyDashboard,
    processCustom,
    haulingProductionDash,
    loadingSpotDash,    
    energyReceive,
    fileServer,
    documentType,
    machineDocument,   
    warehouse, 
    projectTrcloud,
    pmCategory,
    pmUnit,
    pmPart,
    trcloud,
    pmTable,
    pmTableItem,
    pmActivity,
    pmRound,
    pmTableMachine,
    pmActualRound,
    pmFramework,
    pmAppointment,
    pmAppointmentAction,
    pmMrApproval,
    pmMr,
    machineBlockMr,
    pmDocFile,
    pmPartFile,
    pmPrint ,
    pmDash,
    vender,
    machineGroup,
    machineGroupItem,
    distanceMatrix,
    reportITD,
    energyConsumptionGroup,
    crawlerDrillDash,
    logLogin
    
  }
})

export default store
